import React from 'react';
import Layout from 'src/Layout';
import { StaticImage } from 'gatsby-plugin-image';

import * as Hero from 'src/page-styles/hero.styled';
import * as Base from 'src/page-styles/base.styled';

const ErrorPage: React.FunctionComponent = () => {
  return (
    <Layout>
      <Hero.Wrapper style={{ height: '100vh' }}>
        <Hero.Cover />
        <StaticImage
          className="hero-image"
          src="../images/404.jpg"
          alt="hero"
        />
        <Hero.Text>
          <Base.Heading style={{ fontWeight: 'bold', textAlign: 'left' }}>
            PAGE NOT FOUND
          </Base.Heading>
          <Base.Paragraph>
            Sorry, we could not find the page you were looking for.
          </Base.Paragraph>
        </Hero.Text>
      </Hero.Wrapper>
    </Layout>
  );
};

export default ErrorPage;
